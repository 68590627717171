@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --border: 240 5.9% 90%;
    --radius: 0.5rem;
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }

  body {
    @apply bg-white text-gray-900 antialiased;
    color: rgb(var(--foreground-rgb));
    background: linear-gradient(
      to bottom,
      rgb(var(--background-start-rgb)),
      rgb(var(--background-end-rgb))
    );
    font-family: var(--font-space);
  }

  code, pre {
    font-family: var(--font-mono);
  }
}

@layer components {
  .social-icon {
    @apply w-10 h-10 flex items-center justify-center rounded-full border border-gray-200 hover:border-gray-400 transition-colors text-gray-600 hover:text-black;
  }
}

/* Font Awesome fixes */
.fa-brands {
  font-family: "Font Awesome 6 Brands" !important;
}

.fancy-text {
  background: linear-gradient(to right, #60a5fa, #c084fc, #60a5fa);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  background-size: 200% auto;
  animation: shine 8s linear infinite;
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}
